import React, { Component } from 'react'
import { Pressable, View, Text } from 'react-native'
import CONSTANTS from '../../utils/constants'
import COLORS from '../../utils/colors'
import styles from './styles'
import STRINGS from '../../utils/strings'

export interface SystemMsgProps {
    msgType?: 'error' | 'warning' | 'info'
    systemMsg: string
    rightIcon?: React.ReactNode
    containerStyle?: object
    textContainerStyle?: object
    iconContainerStyle?: object
    maxlength?: number
    onPressIcon?: () => void
    testID?: string
}

export const getBackgroundColor = (msgType: 'error' | 'warning' | 'info') => {
    switch (msgType) {
        case CONSTANTS.ERROR:
            return COLORS.COLOR_ERROR_BGD
        case CONSTANTS.WARNING:
            return COLORS.COLOR_WARNING
        case CONSTANTS.INFO:
            return COLORS.COLOR_INFO
        default:
            return COLORS.COLOR_INFO
    }
}

export const getColor = (msgType: 'error' | 'warning' | 'info') => {
    switch (msgType) {
        case CONSTANTS.ERROR:
            return COLORS.COLOR_RED
        case CONSTANTS.WARNING:
            return COLORS.COLOR_WARNING_BORDER
        case CONSTANTS.INFO:
            return COLORS.COLOR_PURPLE
        default:
            return COLORS.COLOR_PURPLE
    }
}

class SystemMsg extends Component<SystemMsgProps> {
    render() {
        const {
            msgType,
            systemMsg,
            rightIcon,
            containerStyle,
            textContainerStyle,
            iconContainerStyle,
            maxlength,
            onPressIcon,
            testID,
        } = this.props

        return (
            <View
                style={[
                    styles.container,
                    { backgroundColor: getBackgroundColor(msgType) },
                    { borderLeftColor: getColor(msgType) },
                    containerStyle,
                ]}
                testID={testID}
            >
                <Text
                    style={[
                        styles.textContainer,
                        { color: getColor(msgType) },
                        textContainerStyle,
                    ]}
                >
                    {systemMsg.length < maxlength
                        ? `${systemMsg}`
                        : `${systemMsg.substring(0, maxlength)}...`}
                </Text>

                {rightIcon && (
                    <Pressable
                        style={[styles.iconContainer, iconContainerStyle]}
                        testID={STRINGS.SYSTEM_MSG_ICON}
                        onPress={() => onPressIcon && onPressIcon()}
                    >
                        {rightIcon}
                    </Pressable>
                )}
            </View>
        )
    }
}

export default SystemMsg
