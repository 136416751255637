import React from "react";
// Customizable Area Start
import Loader from "./Loader.web";
import Footer from "../../../components/src/footer.web";
import Header from "../../../components/src/Header.web";
import { Box, Paper, Typography,IconButton ,Dialog} from "@material-ui/core";
import { Button, styled } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { downloadwhite ,closeIcon,genaretingDownload} from "./assets";

// Customizable Area End
import SpecificationPageController, { Props } from "./SpecificationPageController.web";

export default class SpecificationPage extends SpecificationPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    DocumentDisplay = () => {
        const { document } = this.state.detailsData;
        return (
            <StyledPaper>
                { ( document.field_of_invention &&
                    <StyledBox id="section-title-0">
                        <Typography>
                            <strong>{document.field_of_invention} </strong>
                            <Box
                                className="key-li-style"
                                dangerouslySetInnerHTML={{ __html: document.field_of_invention_specification }}
                            />
                        </Typography>
                    </StyledBox>
                )}
                { (document.background_of_invention &&
                    <StyledBox id="section-title-1">
                        <Typography>
                            <strong>{document.background_of_invention} </strong>
                            <Box
                                className="key-li-style"
                                dangerouslySetInnerHTML={{ __html: document.background_of_invention_specification }}
                            />
                        </Typography>
                    </StyledBox>
                )}
                { (document.summary_of_invention &&
                    <StyledBox id="section-title-2">
                        <Typography>
                            <strong>{document.summary_of_invention} </strong>
                            <Box
                                className="key-li-style"
                                dangerouslySetInnerHTML={{ __html: document.summary_of_invention_specification }}
                            />
                        </Typography>
                    </StyledBox>
                )}
                { (document.detailed_description_of_invention &&
                    <StyledBox id="section-title-3">
                        <Typography>
                            <strong>{document.detailed_description_of_invention}</strong>
                            <Box
                                className="key-li-style"
                                dangerouslySetInnerHTML={{ __html: document.detailed_description_of_invention_specification }}
                            />
                        </Typography>
                    </StyledBox>
                )}
                { ( document.claims &&
                    <StyledBox id="section-title-4">
                        <Typography>
                            <strong>{document.claims} </strong>
                            <Box
                                className="key-li-style"
                                dangerouslySetInnerHTML={{ __html: document.claims_specifications }}
                            />
                        </Typography>
                    </StyledBox>
                )}
                { ( document.abstract &&
                    <StyledBox id="section-title-5">
                        <Typography>
                            <strong>{document.abstract} </strong>
                            <Box
                                className="key-li-style"
                                dangerouslySetInnerHTML={{ __html: document.abstract_specifications }}
                            />
                        </Typography>
                    </StyledBox>
                )}
            </StyledPaper>
        );
    };
    // Customizable Area End

    render() {
        return (
            <>
                <Header title={""} />
                <SpecificationPageCss>
                    <ButtonContainer>
                        <BackArrowButton id="back-arrow" onClick={() => this.goBackToPublicationPage()}>
                            <ArrowBackIosNewIcon sx={{ color: '#000' }} />
                        </BackArrowButton>
                        <DownloadButton id="download" onClick={() => this.downloadModelOpen()}>
                            <img src={downloadwhite} alt="download" />
                            <DownloadText>Download</DownloadText>
                        </DownloadButton>
                        <ReportDialog
                            open={this.state.downReportModel}
                            fullWidth
                            aria-labelledby="customized-dialog-title"
                            id="downloadClose"
                            PaperProps={{
                                elevation: 0,
                                className: "download-modal",
                            }}
                            BackdropProps={{
                                className: "backdrop-modal",
                            }}
                        >
                            <Box className="download-closeIconStyle">
                                <IconButton aria-label="close"
                                    disabled={this.state.downloadclose}
                                >
                                    <img
                                        src={closeIcon}
                                        width={24}
                                        alt="closeIcon"
                                        id="download-Click-Close"
                                        height={24}
                                        onClick={() => this.downloadModelClose()}
                                    />
                                </IconButton>
                            </Box>
                            <Box className="down-box">
                                <Box className="download-main-box">
                                    <img
                                        className="download-Icon-style"
                                        src={genaretingDownload}
                                        alt="genaretingDownload"
                                    />
                                    <Box>
                                        <Box className="download-title-model"> We are generating your report,</Box>
                                        <Box className="download-model-Text">Once completed you can Download it from your Profile Settings</Box>
                                    </Box>
                                </Box>
                            </Box>
                        </ReportDialog>
                    </ButtonContainer>
                    <Loader loading={this.state.loading} />
                    <>
                        {!this.state.errors ? (
                            <ContentContainer>
                                {this.DocumentDisplay()}
                            </ContentContainer>
                        ) : (
                            <NoResultText>No Results Found</NoResultText>
                        )}
                    </>
                </SpecificationPageCss>
                <Footer />
            </>
        );
    }
}

// Customizable Area Start
const ButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2% 5%',
    marginBottom: '15px',
});

const DownloadButton = styled(Box)({
  padding: "10px 16px",
  cursor: "pointer",
  background: "#FF9800",  
  gap: "10px",
  alignItems: "center",
  borderRadius: "8px",
  display: "flex",
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
});

const DownloadText = styled(Box)({
  color: "#FFFFFF",
  fontFamily: "Inter",
  fontWeight: 700,
  fontSize: "16px",
});

const BackArrowButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
});

const ContentContainer = styled(Box)({
    paddingTop: "10px",
    paddingBottom: "50px",
    paddingLeft: "5%",
    paddingRight: "5%",
    background: "#E8E7E7",
    "@media(max-width:1050px)": {
        padding: "40px 40px",
    },
    "@media(max-width:920px)": {
        padding: "40px 20px",
    },
});

const StyledBox = styled(Box)({
    gap: 16,
    display: "flex",
    alignItems: "center",
    // marginBottom: "24px",
});

const StyledBox1 = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 0",
    position: "relative",
});
const VerticalGradientLine = styled('div')({
    width: "4px",
    height: "40px",
    background: "linear-gradient(rgb(227, 219, 213), rgb(232, 229, 219))",
    position: "absolute",
    right: "0",
    top: "50%",
    transform: "translateY(-50%)",
});

const StyledPaper = styled(Paper)({
    padding: 15,
    paddingTop: 35,
    backgroundColor: '#ffffff',
    border: '15px solid rgb(236, 226, 226)',
});

const NoResultText = styled(Box)({
    fontWeight: 700,
    fontSize: 24,
    fontFamily: "Inter",
    justifyContent: "center",
    height: "200px",
    display: "flex",
    alignItems: "center",
});

const SpecificationPageCss = styled(Box)({
    "& .key-li-style": {
        fontFamily: 'Inter',
        fontSize: 16,
      },

    "& .key-li-style img": {
    maxWidth: '20%',        // Make the image responsive
    height: 'auto',          // Maintain aspect ratio
    borderRadius: '8px',     // Border radius for the image
    margin: '10px 0',        // Margin around the image
    display: 'block',        // Center the image
    marginRight: 'auto',
  },
      
});

const ReportDialog = styled(Dialog)({
    "& .download-modal": {
      borderRadius: 0,
      background: '#F5F5F4',
      maxWidth: 738,
      height: 298,
    },
    "& .backdrop-modal": {
      backgroundColor: 'rgba(15, 23, 42, 0.4)'
    },
    "& .download-main-box": {
      alignItems: "center",
      gap: 30,
      flexDirection: "column",
      display: "flex",
      textAlign: "center",
    },
    "& .download-Icon-style": {
      cursor: 'pointer',
    },
    "& .download-title-model": {
      fontFamily: 'Inter',
      fontWeight: 700,
      color: '#4F5356',
      marginBottom: "15px",
      fontSize: 20,
    },
    "& .download-model-Text": {
      fontWeight: 700,
      fontFamily: 'Inter',
      color: '#4F5356',
      fontSize: 20,
    },
    "& .down-box": {
      padding: 60,
      height: "100%",
    },
    "& .download-closeIconStyle": {
      position: 'absolute',
      right: 10,
      top: 8,
    },
  });
// Customizable Area End
