import React from 'react'
import {
    View,
    Text,
    StyleSheet,
    StyleProp,
    ViewStyle,
    TextStyle,
} from 'react-native'
import moment from 'moment'
import SegmentedControl from '../SegmentedControl'
import { Mode } from 'react-native-big-calendar'
import Button from '../Button/Button'

export interface CalendarHeaderStyleProps {
    containerStyle?: StyleProp<ViewStyle>
    dateTextStyle?: StyleProp<TextStyle>
    controlContainerStyle?: StyleProp<ViewStyle>
    segmentedControlContainerStyle?: StyleProp<ViewStyle>
}

export interface CalendarHeaderProps extends CalendarHeaderStyleProps {
    date: Date
    mode: Mode
    setMode: (mode: Mode) => void
    setDate: (date: Date) => void
}

const formatDate = (mode: Mode, date: Date) => {
    if (mode === 'month') {
        return moment(date).format('MMMM YYYY')
    }
    if (mode === 'week') {
        const startOfWeek = moment(date).startOf('isoWeek')
        const endOfWeek = moment(date).endOf('isoWeek')

        // Format the week range
        return `${startOfWeek.format('MMM D')} - ${endOfWeek.format('MMM D')}`
    }
    return moment(date).format('dddd, MMM D YYYY')
}

export const CalendarHeader: React.FC<CalendarHeaderProps> = ({
    date,
    mode,
    setMode,
    setDate,
    containerStyle,
    dateTextStyle,
    controlContainerStyle,
    segmentedControlContainerStyle,
}) => {
    const formattedDate = formatDate(mode, date)

    const handleTabChange = (item: { id: string; name: string }) => {
        setMode(item.id as Mode)
    }

    const onPressPrev = () => {
        let newDate = new Date(date)
        if (mode === 'month') {
            newDate.setMonth(newDate.getMonth() - 1)
        } else if (mode === 'week') {
            newDate.setDate(newDate.getDate() - 7)
        } else {
            newDate.setDate(newDate.getDate() - 1)
        }
        setDate(newDate)
    }

    const onPressNext = () => {
        let newDate = new Date(date)
        if (mode === 'month') {
            newDate.setMonth(newDate.getMonth() + 1)
        } else if (mode === 'week') {
            newDate.setDate(newDate.getDate() + 7)
        } else {
            newDate.setDate(newDate.getDate() + 1)
        }
        setDate(newDate)
    }

    // TODO: Expose this as a prop in the future to allow more customisability
    const segmentedControlData = [
        { id: 'day', name: 'Day' },
        { id: 'week', name: 'Week' },
        { id: 'month', name: 'Month' },
    ]

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={{ alignItems: 'flex-start' }}>
                <Text style={[styles.dateText, dateTextStyle]}>
                    {formattedDate}
                </Text>
            </View>
            <View
                testID={'control'}
                style={[styles.controlContainer, controlContainerStyle]}
            >
                <Button icon={<Text>{'<'}</Text>} onPress={onPressPrev} />
                <Button icon={<Text>{'>'}</Text>} onPress={onPressNext} />
                <SegmentedControl
                    dataSet={segmentedControlData}
                    size="medium"
                    containerStyle={[
                        styles.segmentedControlContainer,
                        segmentedControlContainerStyle,
                    ]}
                    itemStyle={styles.segmentedControlItem}
                    itemSelectdBgColor="#D1C4E9"
                    itemSelectdTxtColor="#6200EE"
                    initialSelectedId={mode}
                    onPressItem={handleTabChange}
                />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: 16,
        backgroundColor: '#FFF',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    dateText: {
        fontSize: 20,
        fontWeight: 'bold',
        marginHorizontal: 16,
        textAlign: 'center',
    },
    controlContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    segmentedControlContainer: {
        flexDirection: 'row',
    },
    segmentedControlItem: {
        paddingHorizontal: 16,
        paddingVertical: 8,
        borderRadius: 12,
    },
})

export default CalendarHeader
