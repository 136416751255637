Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "KeywordExtractor2";
exports.labelBodyText = "keywordextractor2 Body";
exports.emailPlaceholder = "Enter email"
exports.keywordPlaceholder = "Enter Keywords"
exports.btnExampleTitle = "CLICK ME";
exports.loginButton = "Login";
exports.httpPostMethod = "POST"
exports.keywordButton = "Search"
exports.keywordExtractorPoint = '/bx_block_keywordextractor2/keyword_extraction/extract_keywords'
// Customizable Area End