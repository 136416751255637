import React from "react";


// Customizable Area Start
import { Input, Button, Typography, Text } from '@builder/component-library';

import { View, StyleSheet } from "react-native";

// Customizable Area End

import Keywordextractor2Controller, {
  Props,
  configJSON,
} from "./Keywordextractor2Controller";

export default class Keywordextractor2 extends Keywordextractor2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={{ display: "flex", alignItems: "center" }}>
        <View style={styles.mainWrapper}>
          <Typography style={styles.titleText}>{configJSON.labelTitleText}</Typography>
          <View style={styles.inputStyle}>
            <Input
              data-testid="handleChangeKeyword"
              placeholder={configJSON.keywordPlaceholder}
              label={`Enter Keywords`}
              value={this.state.keywords}
              onChangeText={this.handleChangeKeyword}
              containerStyle={{ marginBottom: "1rem" }}
            />
          </View>
          <View style={{ ...styles.buttonStyle, marginTop: "5px" }}>
            <Button
              data-testid="createKeywordSearch"
              style={{ ...styles.buttonStyle, display: "flex", alignItems: "center", justifyContent: "center" }}
              onPress={this.createKeywordSearch.bind(this)} text={configJSON.keywordButton} />
          </View>
          <View style={{ backgroundColor: "white", width: "100%", height: "80px", paddingTop: "30px" }}>
            <Text style={{ color: "black", fontSize: 18 }}>{this.state.keywordData[String(localStorage.getItem("keyword"))]}</Text>
          </View>
        </View>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif",
    marginTop: "15%",
    padding: "1rem"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",
  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "1rem",
    marginBottom: "2rem"
  },
  buttonStyle: {
    width: "100%",
    height: "40px",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    borderRadius: 8
  },
});


// Customizable Area End
