import React, { Component } from 'react'
import {
    View,
    FlatList,
    Text,
    Pressable,
    StyleProp,
    ViewStyle,
    TextStyle,
    PressableProps,
} from 'react-native'
import styles from './styles'
import STRINGS from '../../utils/strings'
import COLORS from '../../utils/colors'
import { LinearGradientWrapper } from '../../molecules/LinearGradientWrapper'

export type TabData = Array<
    {
        img?: React.ReactNode
        selectedImg?: React.ReactNode
        name?: string
        id: number
    } & PressableProps
>

export interface BottomTabProps extends PressableProps {
    tabData: TabData
    containerStyle?: StyleProp<ViewStyle>
    activeIndex?: number
    tabStyle?: StyleProp<ViewStyle>
    selectedTabStyle?: StyleProp<ViewStyle>
    textStyle?: StyleProp<TextStyle>
    selectedTextStyle?: StyleProp<TextStyle>
    onIndexChange?: (text: string) => void
    gradientColors?: [string, string]
    isGradient?: boolean
}

export interface BottomTabState {
    selectedItem?: any
}

export class BottomTab extends Component<BottomTabProps, BottomTabState> {
    constructor(props: BottomTabProps) {
        super(props)
        this.state = {
            selectedItem: null,
        }
    }

    componentDidMount() {
        const { activeIndex } = this.props
        this.setState({ selectedItem: activeIndex })
    }

    componentDidUpdate(prevProps: BottomTabProps) {
        if (prevProps.activeIndex !== this.props.activeIndex) {
            this.setState({ selectedItem: this.props.activeIndex })
        }
    }

    onPress = (index) => {
        this.setState({ selectedItem: index })
        const { onIndexChange } = this.props
        if (onIndexChange) {
            onIndexChange(index)
        }
    }

    renderItem = ({ item, index }) => {
        const { selectedItem } = this.state
        const isSelected = selectedItem === index

        const { img, selectedImg, id, name, ...pressableProps } = item

        return (
            <Pressable
                onPress={() => this.onPress(index)}
                testID={STRINGS.BOTTOM_TAB}
                style={[
                    styles.tab,
                    isSelected
                        ? [this.props.selectedTabStyle]
                        : this.props.tabStyle,
                ]}
                {...pressableProps}
            >
                {img && (isSelected ? selectedImg || img : img)}
                <Text
                    style={[
                        styles.text,
                        isSelected
                            ? [
                                  styles.selectedText,
                                  this.props.selectedTextStyle,
                              ]
                            : [this.props.textStyle],
                    ]}
                >
                    {name.slice(0, 12)}
                </Text>
            </Pressable>
        )
    }

    render() {
        const {
            tabData,
            containerStyle,
            gradientColors = [COLORS.COLOR_LIGHTBLUE, COLORS.COLOR_MEDIUMBLUE],
            isGradient = false,
            testID,
        } = this.props

        return (
            <View style={containerStyle} testID={testID}>
                <LinearGradientWrapper
                    isGradient={isGradient}
                    colors={gradientColors}
                    style={styles.container}
                >
                    <FlatList
                        horizontal
                        data={tabData}
                        keyExtractor={(item: any) => item.id}
                        renderItem={this.renderItem}
                        contentContainerStyle={[styles.contentContainer]}
                    />
                </LinearGradientWrapper>
            </View>
        )
    }
}
