import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { keyFeatureImg1, keyFeatureImg2, keyFeatureImg3, keyFeatureImg4, keyFeatureImg5, keyFeatureImg6 } from "./assets";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";

interface GridImages {
  imag: string
};

interface Product {
  position: number,
  title: string,
  link: string,
  product_link: string,
  product_id: string,
  serpapi_product_api: string,
  number_of_comparisons: string,
  comparison_link: string,
  serpapi_product_api_comparisons: string,
  source: string,
  price: string,
  extracted_price: number,
  rating: number,
  reviews: number,
  extensions: string[],
  thumbnail: string,
  thumbnails: string[],
  tag: string,
  delivery: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  gridView: boolean;
  productDetails: Product[]
  seTselectedProductNumbers:any
  clearTrigger:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  girdViewImg: GridImages[],  
  selectedProductNumbers: string[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProductPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      girdViewImg: [
        { imag: keyFeatureImg1 },
        { imag: keyFeatureImg2 },
        { imag: keyFeatureImg3 },
        { imag: keyFeatureImg4 },
        { imag: keyFeatureImg5 },
        { imag: keyFeatureImg6 },
      ],
      selectedProductNumbers: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const loginToken = await getStorageData("token")
    if(!loginToken){
      this.backToLogInPage()
    }
  };

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.clearTrigger !== this.props.clearTrigger) {
      this.setState({ selectedProductNumbers: [] });
    }
  }
  backToLogInPage = async () => {
    const messageBackLogin: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    messageBackLogin.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    messageBackLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    messageBackLogin.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageBackLogin);
  };

  onChangePublicationselect = (publicationNumber: string) => {
    this.setState((prevState) => {
      const isAlreadySelected = prevState.selectedProductNumbers.includes(publicationNumber);
      const updatedSelection = isAlreadySelected
        ? prevState.selectedProductNumbers.filter((num) => num !== publicationNumber)
        : [...prevState.selectedProductNumbers, publicationNumber];
        this.props.seTselectedProductNumbers(updatedSelection);
      return { selectedProductNumbers: updatedSelection };
    });
  };

  // Customizable Area End
}
