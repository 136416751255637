import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

interface Attributes {
  user_name: string;
  message: string;
  email: string;
  activated: boolean;
};

interface Data {
  id: string;
  type: string;
  attributes: Attributes;
};

interface Meta {
  token: string;
  refresh_token:string
};

interface SuccessResponse {
  data: Data;
  meta: Meta;
  errors: Error[]
};

interface Error {
  failed_login: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  changeIcon: boolean
  formValues: {
    rememberMe: boolean | undefined;
    email: string;
    password: string;
    terms: boolean; 
  },
  formErrors: {
    email: string;
    password: string;
    terms: string;
  },
  loginButton: boolean;
  openTermAndConditionModel: boolean;
  resetPassword: boolean;
  modelType: string;
  termsDoc: string;
  rememberMe: boolean;
  termsAndCondition: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  apiLogInCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  passwordReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      changeIcon: false,
      rememberMe: false,
      formValues: {
        email: "",
        password: "",
        terms: false,
        rememberMe: undefined
      },
      formErrors: {
        email: "",
        password: "",
        terms: ""
      },
      loginButton: false, 
      openTermAndConditionModel: false,
      resetPassword: false,
      modelType: "",
      termsDoc: "",
      termsAndCondition: false
};

    this.emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    this.passwordReg = new RegExp(/^.{8,}$/);
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area End
  }

  // Customizable Area Start
  onClickShowPasswordIcon = () => {
    this.setState({ changeIcon: !this.state.changeIcon })
  }

  validateEmail = (email: string) => {
    if (!email) {
      return 'Please enter email';
    } else if (!this.emailReg.test(email)) {
      return 'Please enter Registered Email';
    } else {
      return '';
    }
  };

  validatePassword = (password: string) => {
    if (!password) {
      return 'Please enter password';
    } else if (!this.passwordReg.test(password)) {
      return 'Password must be at least 8 characters long';
    } else {
      return '';
    }
  };
  
  

  validateTerms = (check: boolean) => {
    if (!check) {
      return 'Please accept Privacy Policy and Terms & Conditions to proceed';
    } else {
      return '';
    }
  };

  handleChangeEmail = (event: { target: { value: string } }) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        email: value
      },
      formErrors: {
        ...prevState.formErrors,
        email: this.validateEmail(value)
      }
    }));
  };

  handleChangePassword = (event: { target: { value: string; }; }) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        password: value,
      },
      formErrors: {
        ...prevState.formErrors,
        password: this.validatePassword(value)
      }
    }));
  }

  handleChangeTerms = (event:any) => {
    const { checked } = event.target;
    
      this.setState((prevState) => ({
   
        termsAndCondition: !this.state.termsAndCondition,
        formValues: {
          ...prevState.formValues,
          terms: checked
        },
        formErrors: {
          ...prevState.formErrors,
          terms: this.validateTerms(checked)
        }
      }));
  };

  handleLogIn = () => {
    const { email, password, terms } = this.state.formValues;
  
    const emailError = this.validateEmail(email);
    const passwordError = this.validatePassword(password);
    const termsError = this.validateTerms(terms);
   
    if (!emailError && !passwordError && !termsError) {
      this.setState({ loginButton: true });
      window.localStorage.clear(); 
      window.sessionStorage.clear();
  
        this.handleChangeRemember(false);
      this.handleLogInApi();
  
      if (this.state.rememberMe) {
        this.handleChangeRemember(true);
      }
    } else {
    
      this.setState({
        formErrors: {
          email: emailError,
          password: passwordError,
          terms: termsError,
        },
      });
    }
  };
  

  handleLogInApi = () => {
    const { email, password, terms } = this.state.formValues;
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const attrs = {
      email: email,      

      password: password,
      terms: terms,
    };

    const logIn = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: logIn,
    };

    const logInMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiLogInCallId = logInMessage.messageId;

    logInMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logInUrl
    );

    logInMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    logInMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    logInMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(logInMessage.id, logInMessage);
  };

  goToLandingPage = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LandingPageWeb"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  goToSignUpPage = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "EmailAccountRegistration"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  goToForgotPasswordPage = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "ForgotPassword"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  hendleOpenTAndCModel = (type: string) => {
    if(this.state.termsDoc && type === "Terms") {
      const link = document.createElement('a');
    link.href = this.state.termsDoc;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(this.state.termsDoc);
    } else {
      this.setState({
        openTermAndConditionModel: true,
        modelType: type
      });
    }
  };
  getTermDoc = (document: string) => {
    this.setState({
      termsDoc: document
    })
  }
  
  handleCloseTAndCModel = () => {
    this.setState({ openTermAndConditionModel: false })
  };

  handleResetPassword = () => {
    this.setState({ resetPassword: true })
  };

  handleCloseResetPassword = () => {
    this.setState({ resetPassword: false })
  };  
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  handleReciverFunc = (apiRequestCallId: string, responseJson: SuccessResponse) => {
    if (apiRequestCallId === this.apiLogInCallId) {
      if (responseJson.errors && responseJson.errors[0].failed_login === "Please enter Registered  Email") {
        this.setState({
          formErrors: { ...this.state.formErrors, email: responseJson.errors[0].failed_login }
        })
      } else if (responseJson.errors && responseJson.errors[0].failed_login === "Please enter correct password") {
        this.setState({
          formErrors: { ...this.state.formErrors, password: responseJson.errors[0].failed_login }
        })
      } else if (responseJson.meta) {
        if(this.state.rememberMe){
          setStorageData("token", responseJson.meta?.refresh_token)
        }else{
          setStorageData("token", responseJson.meta?.token)
        }
        this.setState({
          formErrors: { email: "", password: "", terms: "" },
          formValues: {
            email: "", password: "", terms: true,
            rememberMe: undefined
          },
        })
        this.goToLandingPage();
      }
      this.setState({
        loginButton:false
      })
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            this.openInfoPage();
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            this.sendLoginFailMessage();
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        this.handleReciverFunc(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  // Customizable Area Start 
onChangeCheckBoxForRemember = () => {
  this.setState(
    (prevState) => ({
      rememberMe: !prevState.rememberMe,
    }),
    () => {
      if (this.state.rememberMe) {
        this.handleChangeRemember(false);
      }
    }
  );
}; 
 handleChangeRemember = async(ifFromRemember:any) => {
  const { email, password } = this.state.formValues;
  if (ifFromRemember) {
    const rememberedEmail = await getStorageData('email');
    const rememberedPassword = await getStorageData('password');
      this.setState({
        formValues: {
          ...this.state.formValues,
          email: rememberedEmail,
          password: rememberedPassword,
        },
        
        rememberMe: true,
      });
  } else {

    setStorageData('email', email);
    setStorageData('password', password);
  }
};

// Customizable Area End

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
