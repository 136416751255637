import React from "react";
// Customizable Area Start
import Footer from "../../../components/src/footer.web";
import { closeIcon, keyExpend,  overlapping, whiteCalender,  leftArrow, listView, genaretingDownload, showmappingwhite, downloadwhite, blueCheckedIcon, uncheckedIcon } from "./assets";
import Header from "../../../components/src/Header.web";
import { Box, Dialog, Grid, IconButton, LinearProgress, styled , Checkbox, CircularProgress} from "@material-ui/core";
import Loader from "./Loader.web";
import {Link} from "react-router-dom"
import Chart from 'react-apexcharts';
import { ResolveCondition } from "../../../blocks/utilities/src/ResolveCondition";

// Customizable Area End

import SimilarDocumentsController, {
  Props,
} from "./SimilarDocumentsController.web";

export default class SimilarDocuments extends SimilarDocumentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderKeyFeatureMainText = (item:any, gridView:boolean) => {
    const isChecked = this.state.selectedPatentNumbers.includes(item.publication_number);
    const onCheckboxChange = () => this.onChangepatentselect(item.publication_number);
    return ResolveCondition( gridView,<StyledKeyFeatureMainTextGridView>
      <Checkbox
        checked={isChecked}
        onChange={onCheckboxChange}
        data-test-id="changeCheckBoxDataTestId2"
        checkedIcon={<img src={blueCheckedIcon} alt="checked" />}
        icon={<img src={uncheckedIcon} alt="unchecked" />}
        color="primary"
        className="logIn-checkbox-label"
      />
      
      {item?.title}
    </StyledKeyFeatureMainTextGridView>,<StyledKeyFeatureMainText>
        <Checkbox
          checked={isChecked}
          onChange={onCheckboxChange}
          id="changeCheckBoxDataTestId1"
          checkedIcon={<img src={blueCheckedIcon} alt="checked" />}
          icon={<img src={uncheckedIcon} alt="unchecked" />}
          color="primary"
          className="logIn-checkbox-label"
        />
        {item?.title}
      </StyledKeyFeatureMainText>)
   
  };
  gridViewFunction = () => {    

    return (
      <Box className="keyFeature-gridView">
        <Grid container spacing={3}>
          
          {this.state.similarDocData?.map((item, index) => (
            <Grid item xs={ResolveCondition(this.state.girdView ,6 ,12)} key={index}>
              
              <Box className={ ResolveCondition(this.state.girdView ,"keyFeature-white-container-gridView" , "keyFeature-white-container") }>
                 
               
                
                {this.renderKeyFeatureMainText(item, this.state.girdView)}



                <Grid item xs={12}>
                  <Box id="list-grid" className={this.state.girdView ? "keyFeature-gridView-Id-btn" : "keyFeature-btn-Id"}>
                    
                    {item?.publication_number}

                  </Box>
                </Grid>
                
                {this.state.visibleGraphs.includes(item.publication_number) ? (
                  <Box className={ ResolveCondition(this.state.girdView ,"keyFeature-chart-box-gridView" , "keyFeature-chart-box") }>
                    <Box className={this.getGridViewChartClassName()}>
                      <Chart
                        options={{
                          chart: {
                            height: 274,
                            type: 'line',
                            zoom: {
                              enabled: false
                            },
                            toolbar: {
                              show: false
                            }
                          },
                          xaxis: {
                            categories: [2005, 2006, 2007, 2008],
                            labels: { style: charts.xaxisChart },
                            title: { text: 'Years', style: charts.titleBody },
                          },
                          yaxis: {
                            title: { text: 'Applications', style: charts.yaxisChart },
                            labels: {
                              formatter: (value: { toLocaleString: () => string; }) => value.toLocaleString(),
                              style: charts.labelsCss,
                            },
                          },
                          markers: { size: 5, colors: ['#DC2626'] },
                          stroke: { curve: 'straight' },
                        }}
                        series={[
                          {
                            name: 'Applications',
                            color: '#DC2626',
                            data: [250000, 350000, 450000, 550000],
                          },
                        ]}
                        type="line"
                        height={274}
                        width="100%"
                      />
                    </Box>
                    <Box className={this.getgridViewClassName()}>
                      {
                        this.state.formattedData.find(
                          (formattedItem: any) => formattedItem.publication_number === item.publication_number
                        ) && (
                          <>
                            <Box className="overlapping-head-Box">
                              <Box className="overlapping-text">
                                View Overlapping
                              </Box>
                              <Box className="percent-text">
                                Percent
                              </Box>
                            </Box>
                            <Box className="overlapping-border"></Box>
                          </>
                        )
                      }

                      {(() => {
                        const formattedItem = this.state.formattedData.find(
                          (entry: any) => entry.publication_number === item.publication_number
                        );

                        const errorOverlap = this.state.errorOverLappingData.find(
                          (entry: any) => entry.publication_number === item.publication_number
                        );

                        if (formattedItem) {
                          return formattedItem.mapping.map((mappingItem: any, mappingIndex: any) => (
                            <Box key={`${mappingIndex}`} className="overlapping-box-container">
                              <Box className="overlapping-Label-percentage">
                                <Box className="overlapping-Label">{mappingItem.label}</Box>
                                <Box className="overlapping-percentage">{mappingItem.value}%</Box>
                              </Box>
                              <LinearProgress
                                variant="determinate"
                                value={mappingItem.value}
                                className="KeyFeature-progressStyle"
                              />
                            </Box>
                          ));
                        } else if (errorOverlap) {
                          return (
                            <Box className="overlapping-box-container">
                              <Box  sx={{paddingTop:'110px'}}>
                                {errorOverlap.message}
                              </Box>
                            </Box>
                          );
                        }
                        return null;
                      })()}



                    </Box>
                  </Box>
                ) : (
                  null
                )}
                <Box className="buttons-main-box">
                  <Box className="buttons-box-red" >
                    <img src={showmappingwhite} alt="showMappig" />
                    <Box className="button-text-white" sx={{color:'white'}}  onClick={() => this.goToShowMappingPage()} id="show-mapping" > Show Mapping</Box>
                  </Box>
                  <Box className="buttons-box" data-test-id='togel' onClick={() => this.toggleGraphVisibility(item.publication_number)}>
                    {ResolveCondition(this.state.circularLoader.includes(item.publication_number),<CircularProgress value={10} size={20} />,<img
                        src={overlapping}
                        alt="overlapping" /> )
                   
                      
                        }
                    {ResolveCondition(this.state.visibleGraphs.includes(item.publication_number) ,
                        <Box className="button-text">Hide Overlapping</Box> ,
                        <Box className="button-text">View Overlapping</Box>)
                      
                    }
                  </Box>
                  {this.state.girdView &&
                    <Link to={`/patentdetail/${item.publication_number}`} className="link-text">
                      <Box  className="buttons-box-red" id="descriptionPageGrid">
                        <img src={whiteCalender} alt="whiteCalender" />
                        <Box className="button-text-white">View More </Box>
                      </Box>
                    </Link>
                  }
                  {!this.state.girdView &&
                    <Link className="link-text" to={`/patentdetail/${item.publication_number}`}>
                      <Box 
                      className="buttons-box-red" 
                      id="descriptionPage" 
                      >
                        <img 
                        src={whiteCalender} 
                        alt="whiteCalender" />
                        <Box className="button-text-white">View More</Box>
                      </Box>
                    </Link>
                  }
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  };
  /* istanbul ignore next */
  handleNavigation = () => {
    const { selectResult, loading, similarDocData,} = this.state;
    const hasPatentResults = similarDocData?.length > 0;
    return (
      <Box>
        {selectResult === 'Patent' && !loading && (
          hasPatentResults ? (
            this.gridViewFunction()
          ) : (
            <Box component="div" className="no-result-text">No Results Found</Box>
          )
        )}
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      <>
        <Loader loading={this.state.loading} />
        <Header title={""} />
        <KeyFeatureLandingPageContainerCss>
          <Grid container>
            <Box 
            className="back-arrowimain-box"
            >
              <Box 
              className="back-arrow-box backarrowbox"
              >
                <img src={leftArrow} alt="leftarrow" onClick={() => this.goBackToLandingPage()} className="back-arrow-img" id="navigate-landingPage"  />
                <Box  id="heading" className="back-arrow-text" >
                  View Key Features
                  </Box>
              </Box>
            </Box>
          </Grid>
          <Box 
          className="result-Main"
          >
            <Box>
              <Box className="caseId-main">  Case ID : <Box component="span" className="box-span">{this.state.case_id}</Box>
              </Box>
              
              <Box className="top-ten-result totalResult">Showing top {this.state.similarDocData.length} results</Box>
              <Box className="keyfeature-box">
                <Grid item xs={12} 
                className="keyFeature-search-grid"
                >
                  <Box 
                  className="keyFeature-search-box-looking"
                  >
                    {this.state.selectedTabs.map((item: string, index: number) => (
                      <Box 
                      className={item !== this.state.selectResult ? "keyFeature-box-search-loop" : "keyFeature-box-search-loop-select"}
                        id={`statusChange${index}`} 
                        onClick={() => this.onClickSetStutus(item)}
                        >
                        <Box 
                        key={index} 
                        className="keyFeature-box-name">
                          {item}
                          </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Box 
                className="download-Report-main">
                  <Box className="report-buttons-box report buttonBox"  onClick={() => this.downloadDetails()} id="downloadOpen"
                   >
                    <img 
                    src={downloadwhite} 
                    alt="download"
                     />
                    <Box 
                       className={`report-button-text ${(this.state.selectResult === "Patent" && this.state.selectedPatentNumbers.length > 0) ||
                        (this.state.selectResult === "Publication" && this.state.selectedPublicationNumbers.length > 0) ||
                       (this.state.selectResult === "Products" && this.state.selectedProductNumbers.length > 0)
                       ? "enabled"
                       : "disabled"
                     }`}
                    >Download Report</Box>
                  </Box>
                  <Box className="keyFeature-img-box">
                    <img 
                    className="listView-style listViewStyle" 
                    src={this.state.girdView ? listView : keyExpend} 
                    id="listView" 
                    alt="keyExpend" 
                    onClick={() => this.handleClickGridView()} />
                  </Box>
                </Box>
                <ReportDialog
                  aria-labelledby="customized-dialog-title"
                  open={this.state.downReportModel}
                  id="downloadClose"
                  fullWidth
                  PaperProps={{
                    elevation: 0,
                    className: "download-modal downloadModal",
                  }}
                  BackdropProps={{className: "backdrop-modal"}}
                >
                  <Box 
                  className="download-closeIconStyle download"
                  >
                    <IconButton 
                    aria-label="close"
                    >
                      <img
                        src={closeIcon}
                        alt="closeIcon"
                        id="download-Click-Close"
                        width={24}
                        height={24}
                        onClick={() => this.downloadModelClose()}
                      />
                    </IconButton>
                  </Box>
                  <Box 
                  className="down-box"
                  >
                    <Box className="download-main-box">
                      <img src={genaretingDownload} alt="genaretingdownload" className="download-Icon-style"/>
                      <Box>
                        <Box className="download-title-model downloadTitleModel">We are generating your report,</Box>
                        <Box className="download-model-Text downlodTitleTxt"> Once completed you can Download it from your Profile Settings</Box>
                      </Box>
                    </Box>
                  </Box>
                </ReportDialog>
              </Box>
            </Box>
           {this.handleNavigation()}
          </Box>
        </KeyFeatureLandingPageContainerCss>
        <Footer />
      </>
    );
  }
}

// Customizable Area Start
const ReportDialog = styled(Dialog)({
  "& .download-modal": {
    borderRadius: 0,
    background: '#F5F5F4',
    height: 298,
    maxWidth: 737,
  },
  "& .backdrop-modal": {
    backgroundColor: 'rgba(15, 23, 42, 0.4)'
  },
  "& .download-main-box": {
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    gap: 30,
    flexDirection: "column",
  },
  "& .download-Icon-style": {
    cursor: 'pointer',
  },
  "& .download-title-model": {
    fontFamily: 'Inter',
    marginBottom: "15px",
    fontSize: 20,
    fontWeight: 700,
    color: '#4F5356',
  },
  "& .download-model-Text": {
    fontSize: 20,
    fontWeight: 700,
    color: '#4F5356',
    fontFamily: 'Inter',
  },
  "& .down-box": {
    padding: 60,
    height: "100%",
  },
  "& .download-closeIconStyle": {
    top: 8,
    right: 10,
    position: 'absolute',
  },
});

const charts = {
  xaxisChart: {
    colors: '#DF4833',
    fontSize: '12px',
  },
  titleBody: {
    fontSize: '12px',
    color: '#F0ABA1',
    fontWeight: 'bold',
  },
  yaxisChart: {
    color: '#F0ABA1',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  labelsCss: {
    colors: '#DF4833',
    fontSize: '12px',
  }
};

const KeyFeatureLandingPageContainerCss = styled(Box)({
  "& .back-arrowimain-box": {
    padding: "30px 0px 20px 108px",
    background: "#E8E7E7",
    "@media(max-width:600px)": {
      padding: "40px 20px",
    },
    "@media(max-width:1280px)": {
      padding: "40px 40px"
    },
    "@media(max-width:960px)": {
      padding: "40px 20px"
    },
  },
  "& .back-arrow-box": {
    display: "flex",
    gap: 16,
    alignItems: "center",
  },
  "& .back-arrow-img": {
    cursor: "pointer"
  },
  "& .back-arrow-text": {
    color: "#212324",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
  },
  "& .result-Main": {
    padding: "30px 108px 20px",
    background: "#E8E7E7",
    "@media(max-width:600px)": {
      padding: "40px 20px",
    },
    "@media(max-width:1280px)": {
      padding: "40px 40px"
    },

    "@media(max-width:960px)": {
      padding: "40px 20px"
    },
  },
  "& .keyFeature-white-container": {
    background: "#F5F5F4",
    padding: "32px 24px",
    borderRadius: 8.5,
  },
  "& .no-result-text": {
    fontSize: 24,
    display: "flex",
    fontFamily: "Inter",
    alignItems: "center",
    height: "200px",
    fontWeight: 700,
    justifyContent: "center",
  },
  "& .keyFeature-white-container-gridView": {
    padding: "32px 16px",
    borderRadius: 8,
    background: "#F5F5F4",
    minHeight:"939px",
  },
  "& .keyFeature-text-main": {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 24,
    fontFamily: "Inter",
  },
  "& .keyFeature-main-gridView-text": {
    fontWeight: 700,
    fontFamily: "Inter",
    fontSize: 24,
    marginTop: 24
  },
  "& .box-span": {
    color: "#DF4833"
  },
  "& .keyFeature-img-main": {
    padding: "24px",
    background: "#F0F0F0",
    flexWrap: "wrap",
    height: 172,
    gap: 24,
    overflowY: "scroll",
    display: "flex",
  },
  "& .keyFeature-img-main-gridView": {
    flexWrap: "wrap",
    height: 172,
    display: "flex",
    overflowY: "scroll",
    gap: 24,
    padding: "0 10px",
  },
  "& .img-loop": {
    height: 172,
    objectFit: "contain",
    width: 166,
  },
  "& .img-class": {
    width: 166,
    height: 172,
  },
  "& .keyFeature-btn-Id": {
    fontFamily: "Inter",
    fontWeight: 700,
    color: "#DF4833",
    background: "#FCEDEB",
    fontSize: 24,
    width: "fit-content",
    textTransform: "none",
    marginTop: 24,
    height: 58,
    padding: "0px 24px",
    borderRadius: 8,
    display: "flex",
    border: 'none',
    gap: 8,
    alignItems: "center",
  },
  "& .keyFeature-gridView-Id-btn": {
    color: "#DF4833",
    fontFamily: "Inter",
    fontWeight: 700,
    display: "flex",
    width: "fit-content",
    background: "#FCEDEB",
    borderRadius: 8,
    border: 'none',
    gap: 8,
    fontSize: 24,
    padding: "0px 24px",
    margin: "24px 0",
    alignItems: "center",
    textTransform: "none",
    height: 58,
  },
  "& .keyFeature-p-text": {
    fontWeight: 400,
    marginTop: 10,
    color: "#4F5356",
    fontSize: 16,
    marginBottom: 24,
    fontFamily: "Inter",
  },
  "& .keyFeature-box-search-loop": {
    padding: '0 16px',
    display: 'flex',
    background: "#F1F5F9",
    borderRadius: 4,
    alignItems: 'center',
    height: 46,
    width: "fit-content",
    cursor: "pointer",
    "@media(max-width:550px)": {
      width: "100%",
    },
  },
  "& .keyFeature-gridView": {
    marginTop: 30
  },
  "& .keyFeature-box-search-loop-select": {
    width: "fit-content",
    borderRadius: 4,
    display: 'flex',
    height: 46,
    cursor: "pointer",
    alignItems: 'center',
    background: "#FCEDEB",
    padding: '0 16px',
    "@media(max-width:550px)": {
      width: "100%",
    },
  },
  "& .keyFeature-search-box-looking": {
    gap: 10,
    display: "flex",
    "@media(max-width:550px)": {
      flexWrap: "wrap",
    },
  },
  "& .keyFeature-search-grid": {
    justifyContent: 'space-between',
    display: "flex",
  },
  "& .keyFeature-box-name": {
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 400,
    color: "#64748B",
  },
  "& .top-ten-result": {
    fontSize: 16,
    color: "#DF4833",
    marginBottom: 5,
    fontWeight: 400,
    fontFamily: "Inter",
  },
  "& .caseId-main": {
    fontFamily: "Inter",
    color: "#4F5356",
    fontSize: 24,
    marginBottom: 30,
    fontWeight: 700,
  },
  "& .keyfeature-box": {
    marginBottom: 30,
    display: 'flex',
    "@media(max-width:830px)": {
      flexWrap: "wrap",
    },
  },
  "& .download-Report-main": {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    "@media(max-width:830px)": {
      marginTop: 30
    },
  },
  "& .keyFeature-img-box": {
    borderRadius: 8,
    background: "#F1F5F9",
    padding: 10,
  },
  "& .listView-style": {
    cursor: "pointer"
  },
  "& .keyFeature-box-chart": {
    gap: 32,
    display: 'flex',
    "@media(max-width:900px)": {
      flexDirection: "column",
    },
  },
  "& .keyFeature-box-gridView-chart": {
    gap: 10,
    display: 'flex',
  },
  "& .keyFeature-main-chart-box": {
    width: "35%",
    borderRadius: 8,
    height: "274px",
    padding: "24px",
    background: "#fff",
    "@media(max-width:900px)": {
      width: "94%"
    },
    "@media(max-width:700px)": {
      width: "92%"
    },
    "@media(max-width:500px)": {
      width: "89%"
    },
    "@media(max-width:400px)": {
      width: "85%"
    },
  },
  "& .keyFeature-chart-box-gridView": {
    gap: 10,
    display: 'flex',
  },
  "& .keyFeature-chart-box": {
    gap: 32,
    display: 'flex',
    "@media(max-width:900px)": {
      flexDirection: "column",
    },
  },
  
  "& .keyFeature-chart-box-gridView-main": {
    height: "274px",
    padding: "10px",
    background: "#fff",
    width: "50%",
    borderRadius: 8,
  },
  "& .keyFeature-Overlapping-main-box": {
    padding: "24px",
    background: "#fff",
    height: "274px",
    width: "35%",
    borderRadius: 8,
    "@media(max-width:900px)": {
      width: "94%"
    },
    "@media(max-width:700px)": {
      width: "92%"
    },
    "@media(max-width:500px)": {
      width: "89%"
    },
    "@media(max-width:400px)": {
      width: "85%"
    },
  },
  "& .keyFeature-Overlapping-main-box-gridView": {
    padding: "10px",
    borderRadius: 8,
    width: "50%",
    height: "274px",
    background: "#fff",
  },
  "& .KeyFeature-progressStyle": {
    borderRadius: "10px",
    width: '70%',
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#DF4833"
    }
  },
  "& .overlapping-Label": {
    marginBottom: "5px",
    fontSize: 14,
    color: "#0F172A",
    fontWeight: 400,
    fontFamily: "Inter",
  },
  "& .overlapping-percentage": {
    fontWeight: 700,
    fontSize: "12px",
    color: "#0F172A",
    fontFamily: "Inter",
  },
  "& .overlapping-head-Box": {
    justifyContent: "space-between",
    display: 'flex',
  },
  "& .overlapping-border": {
    margin: "20px 0",
    background: "#E2E8F0",
    width: "100%",
    height: "1px",
  },
  "& .overlapping-text": {
    fontSize: "12px",
    color: "#0F172A",
    fontWeight: 400,
    fontFamily: "Inter",
  },
  "& .percent-text": {
    color: "#64748B",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  "& .overlapping-box-container": {
    marginBottom: 25
  },
  "& .overlapping-Label-percentage": {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  "& .buttons-main-box": {
    flexWrap: "wrap",
    marginTop: 24,
    gap: 20,
    display: "flex",
  },
  "& .buttons-box": {
    cursor: "pointer",
    display: "flex",
    borderRadius: "8px",
    padding: "10px 16px",
    alignItems: "center",
    background: "#F5F5F4",
    border: "1px solid #AEB0B1",
    gap: "10px",
  },
  "& .orange_button":{
    background:'#DF4833',color:'white'
  },
  "& .link-text": {
    textDecoration: "none"
  },
  "& .buttons-box-red": {
    padding: "10px 16px",
    gap: "10px",
    background: "#DF4833",
    display: "flex",
    borderRadius: "8px",
    alignItems: "center",
    cursor: "pointer",
  },
  "& .button-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#4F5356",
    fontFamily: "Inter",
  },
  "& .button-text-white": {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Inter",
    color: "#FFFFFF",
  },
  "& .report-buttons-box": {
    alignItems: "center",
    display: "flex",
    gap: "10px",
    cursor: "pointer",
    width: "200px",
    padding: "10px 0px 10px 16px",
    borderRadius: "8px",
      backgroundColor:'#FF9800',color:'white'                    
  },
  "& .report-button-text": {
    fontSize: "16px",
    color: "white",
    fontWeight: 700,
    fontFamily: "Inter",

  }
});
const StyledKeyFeatureMainTextGridView = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
  fontFamily: 'Inter',
  marginTop: 24,
  paddingBottom: "4px",
  fontSize: 20,
  gap: '10px',
}));
const StyledKeyFeatureMainText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Inter',
  marginBottom: 16,
  fontWeight: 700,
  fontSize: 22,
  gap: '10px', 
}));
// Customizable Area End
