import React, { Component } from 'react'
import {
    View,
    StyleSheet,
    StyleProp,
    ViewStyle,
    TextStyle,
    Text,
} from 'react-native'
import { horizontalScale, verticalScale } from '../../../utils/screenRatio'
import tableStyle from '../style'
import style from '../style'
import contextMenuStyle from '../../ContextMenu/styles'

export interface DynamicRowProps {
    rowData: any
    renderItem: (item: any, index: number) => React.ReactNode
    tableDataContainerStyle?: StyleProp<ViewStyle>
    tableDataTextStyle?: StyleProp<TextStyle>
    index: number
    length: number
    headerData: Array<string>
    testID?: string
    key?: string
    ContextMenu?: React.ReactNode
}

class DynamicRow extends Component<DynamicRowProps> {
    render() {
        const {
            rowData,
            tableDataContainerStyle,
            tableDataTextStyle,
            index,
            length,
            headerData,
            ContextMenu,
            renderItem,
            testID,
        } = this.props

        return (
            <View
                style={[tableStyle.tableRow, { zIndex: length - index }]}
                testID={testID}
            >
                <View style={[tableStyle.header]}>
                    {headerData?.map((headerValue: string, index: number) => {
                        /*
                         *   TODO: when renderItem is provided, there is a potential problem with responsive styling
                         *    as the header cells won't necessarily have the same styling
                         */
                        return renderItem ? (
                            renderItem(rowData[index], index)
                        ) : typeof rowData[index] == 'string' ? (
                            /* TODO: consider tableDataTextStyle - without diverging from main styling */
                            <Text style={[style.headerText]} key={index}>
                                {rowData[index]}
                            </Text>
                        ) : (
                            <View style={[style.headerText]} key={index}>
                                <>{rowData[index]}</>
                            </View>
                        )
                    })}
                    {ContextMenu && (
                        <View
                            style={
                                (tableStyle.autoWidthCell,
                                tableDataContainerStyle)
                            }
                        >
                            {ContextMenu}
                        </View>
                    )}
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginVertical: verticalScale(10),
    },
    row: {
        flexDirection: 'row',
        marginVertical: verticalScale(5),
        borderBottomWidth: 0.2,
        borderColor: '#CBD5E1',
        backgroundColor: 'white',
    },
    input: {
        flex: 1,
        paddingVertical: verticalScale(5),
        paddingHorizontal: horizontalScale(12),
        justifyContent: 'center',
    },
    TextStyle: {
        fontSize: 14,
        color: '#0F172A',
        fontWeight: '400',
        paddingVertical: 8,
    },
})

export default DynamicRow
