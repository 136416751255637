import React from 'react'
import { View } from 'react-native'
import Button from '../Button/Button'
import styles from './styles'

export interface bottomBarProps {
    container?: object
    leftButtonTitle: string
    leftButtonStyle?: object
    leftButtonTextStyle?: object
    rightButtonTitle: string
    rightbtnStyle?: object
    rightBtnTextStyle?: object
    gradientColor1?: string
    gradientColor2?: string
    testID?: string
}

/**
 * @deprecated This component has been deprecated since version 1.1.4 and will not be supported. Please add from buttons as children in Popover component
 */
class FormBottomBar extends React.Component<bottomBarProps> {
    render() {
        const {
            container,
            leftButtonTitle,
            leftButtonStyle,
            leftButtonTextStyle,
            rightButtonTitle,
            rightbtnStyle,
            rightBtnTextStyle,
            gradientColor1,
            gradientColor2,
            testID,
        } = this.props
        return (
            //@ts-ignore
            <View style={[styles.container, container]} testID={testID}>
                <Button
                    style={{ ...styles.leftButtonStyle, ...leftButtonStyle }}
                    textStyle={[styles.leftBtnTextStyle, leftButtonTextStyle]}
                    text={leftButtonTitle}
                />
                <Button
                    style={{ ...styles.rightbtnStyle, ...rightbtnStyle }}
                    textStyle={[styles.rightBtnTextStyle, rightBtnTextStyle]}
                    gradientColors={
                        gradientColor1 && gradientColor2
                            ? [gradientColor1, gradientColor2]
                            : undefined
                    }
                    text={rightButtonTitle}
                />
            </View>
        )
    }
}

export default FormBottomBar
