import { StyleSheet } from 'react-native'
const hs = (value: number) => value
const ws = (value: number) => value
const ms = (value: number) => value

export const styles = StyleSheet.create({
    container: {
        display: 'flex',
    },
    placeHolderContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: ms(0),
        borderColor: '#1B1B3A',
        borderTopLeftRadius: ms(8),
        borderTopRightRadius: ms(8),
        paddingHorizontal: ws(16),
    },
    contentContainer: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        borderBottomLeftRadius: ms(8),
        borderBottomRightRadius: ms(8),
        backgroundColor: 'transparent',
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderRadius: ms(0),
        backgroundColor: 'transparent',
        marginTop: hs(2),
        padding: ws(16),
        alignItems: 'center',
    },
    selectedListContainer: {
        backgroundColor: '#E1CCFF',
    },
    placeholderTextStyle: {
        fontSize: ms(18),
        fontWeight: '300',
        color: 'black',
    },
    placeholderLabelTextStyle: {
        fontSize: ms(18),
        fontWeight: '300',
        color: 'black',
    },
    dropdownContainer: {
        display: "flex",
        flexDirection: "row"
    },
    imageContainer: {
        display: 'flex',
        overflow: 'hidden',
        alignSelf: 'center',
    },
    iconContainer: {
        display: 'flex',
        overflow: 'hidden',
        marginRight: hs(8),
    },
    image: {
        height: hs(20),
        width: hs(20),
    },
})
