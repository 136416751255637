import React, { Component } from 'react'
import {
    FlatList,
    ViewStyle,
    TextStyle,
    PressableProps,
    StyleProp,
} from 'react-native'
import styles from './styles'
import SegmentedCtrlItem, { SegmentedCtrlItemData } from './SegmentedCtrlItem'

import CONSTANTS from '../../utils/constants'

export interface SegmentedControlProps extends Omit<PressableProps, 'onPress'> {
    size?: 'small' | 'medium' | 'big'
    textStyle?: StyleProp<TextStyle>
    /** data to be displayed on the flatlist */
    dataSet?: Array<SegmentedCtrlItemData>
    /** style for the flatlist container */
    containerStyle?: StyleProp<ViewStyle>
    /** style for the segmented control item container */
    itemStyle?: ViewStyle
    /** selected background color for the selected segmented control item */
    itemSelectdBgColor?: string
    /** selected text color for the selected segmented control item */
    itemSelectdTxtColor?: string
    /** the id of the initial active item */
    initialSelectedId?: string
    onPressItem: (item: SegmentedCtrlItemData) => void
}

interface SegmentedControlState {
    selectedId: string | undefined
}

export const getSize = (size: 'small' | 'medium' | 'big') => {
    switch (size) {
        case CONSTANTS.SIZE_SMALL:
            return 32
        case CONSTANTS.SIZE_BIG:
            return 56
        case CONSTANTS.SIZE_MEDIUM:
        default:
            return 44
    }
}

class SegmentedControl extends Component<
    SegmentedControlProps,
    SegmentedControlState
> {
    constructor(props: SegmentedControlProps) {
        super(props)
        this.state = {
            selectedId: props.initialSelectedId,
        }
    }

    onPressItem = (item: SegmentedCtrlItemData) => {
        this.setState({ selectedId: item?.id })
    }

    render() {
        const {
            size,
            textStyle,
            dataSet,
            containerStyle,
            itemStyle,
            itemSelectdBgColor = '',
            itemSelectdTxtColor = '',
            onPressItem,
            testID,
            ...otherPressableProps
        } = this.props
        const { selectedId } = this.state

        return (
            <FlatList
                testID={testID}
                contentContainerStyle={[styles.flatlist, containerStyle]}
                data={dataSet}
                keyExtractor={(item: any) => `${item?.id}`}
                horizontal={true}
                renderItem={({ item, index }) => (
                    <SegmentedCtrlItem
                        item={item}
                        index={index}
                        //@ts-ignore
                        size={getSize(size)}
                        textStyle={textStyle}
                        itemStyle={itemStyle}
                        itemSelectdBgColor={itemSelectdBgColor}
                        itemSelectdTxtColor={itemSelectdTxtColor}
                        onPress={() => {
                            this.onPressItem(item)
                            onPressItem && onPressItem(item)
                        }}
                        selectedId={selectedId}
                        testID={testID}
                        {...otherPressableProps}
                    />
                )}
            />
        )
    }
}

export default SegmentedControl
