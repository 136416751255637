import React from "react";
import { Box, Dialog, DialogContent } from "@material-ui/core";

interface Props {
  loading: boolean;
}

export default function Loader(props: Props) {
  return props.loading ? (
    <Dialog
      open={props.loading}
      maxWidth={"xs"}
      fullWidth PaperProps={{
        elevation: 0,
        style: {
          background: "transparent"
        }
      }}>
      <DialogContent>
        <Box style={webStyles.root}>
          Fetching results...
        </Box>
      </DialogContent>
    </Dialog>
  ) : (
    <div />
  );
}

export function SavingLoader(props: Props) {
  return props.loading ? (
    <Dialog
      open={props.loading}
      maxWidth={"xs"}
      fullWidth
      PaperProps={{
        elevation: 0,
        style: {
          background: "transparent"
        }
      }}
    >
      <DialogContent>
        <Box style={webStyles.root}>
          Saving your data...
        </Box>
      </DialogContent>
    </Dialog>
  ) : (
    <div />
  );
}

const webStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    margin: "50px 0",
    fontFamily: "Inter",
    fontWeight: 'bold',
    fontSize: '20px',
    padding:'8px',
    color: 'white',
  },
};