import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface KeywordExtractor{
  [keys:string]: { "vgu": number }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  keywords:string;
  password:string;
  isPasswordToggle: boolean;
  keywordData: KeywordExtractor;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Keywordextractor2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  keywordExtractorApiCallId:string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      keywords:"",
      password:"",
      isPasswordToggle:false,
      keywordData: {keywords:{vgu:0}},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    
    this.handleCreateKeywordExtractor(apiRequestCallId, responseJson);
    
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source:imgPasswordVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const keywordsData = JSON.parse(String(localStorage.getItem("extractKeywords")))
    this.setState({keywordData: keywordsData.keywords})
  }

  handleChangeKeyword = (text:string)=>{
    this.setState({keywords:text})
  }

  handleCreateKeywordExtractor = (apiRequestCallId:any, responseJson:any)=>{
    if(apiRequestCallId ===this.keywordExtractorApiCallId){
      if(responseJson){
        this.setState({keywords:""})
        localStorage.setItem("extractKeywords", JSON.stringify(responseJson.data))

        const keywordsData = String(localStorage.getItem("extractKeywords"))
        this.setState({keywordData: JSON.parse(keywordsData).keywords})
      }
    }
  }

  createKeywordSearch = ()=> {
    if(this.state.keywords ===''){
      return
    }
    
    localStorage.setItem("keyword",this.state.keywords)
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const formData = new FormData();
    formData.append("type", "text");
    formData.append("abstract", this.state.keywords);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.keywordExtractorApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.keywordExtractorPoint
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
