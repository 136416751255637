import React from "react";
// Customizable Area Start
import { Box, Checkbox, Grid, styled } from "@material-ui/core";
import { blueCheckedIcon, document, uncheckedIcon, whiteCalender } from "./assets";
import { Link } from "react-router-dom"

// Customizable Area End

import PublicationLandingPageController, {
  Props,
} from "./PublicationLandingPageController";

export default class PublicationLandingPage extends PublicationLandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <>
        <PublicationLandingPageContainerCss>
          <Grid container spacing={3}>
            {this.props.publicationSerpData?.map((item, index) => (
              <Grid item key={index} xs={this.props.gridView ? 6 : 12}>
                <Box className={this.props.gridView ? "publication-white-container-gridView" : "publication-white-container"}>
                  <StyledPublicationMainText className="publication-main-text">
                    <Checkbox
                      checked={this.state.selectedPublicationNumbers.includes(item.scholar_id)}
                      onChange={() => this.onChangePublicationselect(item.scholar_id)}
                      data-test-id="changeCheckBoxDataTestId"
                      checkedIcon={<img src={blueCheckedIcon} alt="checked" />}
                      icon={<img src={uncheckedIcon} alt="unchecked" />}
                      color="primary"
                      className="logIn-checkbox-label"
                    />
                    {item?.title}
                  </StyledPublicationMainText>
                  <MarginSlide style={{marginLeft:"8px"}}>
                  <Box className="publication-buttons-main-box" style={{ display: 'flex', alignItems: 'center' }}>
                    <Box className="publication-Id-btn-text" id="list-grid">
                      {item?.scholar_id?.replace('scholar/', '')}
                    </Box>
                    <Box className="publication-buttons-main-box-author">
                      <Box className="publication-Id-btn-text-author" id="list-grid-publication_date">
                        {item?.publication_date}
                      </Box>
                      <Box className="publication-Id-btn-text-author" id="list-grid-inventor">
                        {item?.author}
                      </Box>
                  </Box>
                  </Box>
                  <Box className="text-box-main">
                    <Box className="publication-text-bold" id="heading">
                      Summary
                    </Box>
                    <Box className="publication-p-text">
                      {item?.snippet}
                    </Box>
                  </Box>
                  <Box className="publication-buttons-main-box">
                    <Box className="publication-buttons-box">
                      <img src={document} alt="document" />
                      <Link to={`/PublicationSimilarDocuments/${item.scholar_id}`} className="a-class">
                      <Box className="publication-button-text">
                        Similar Publications
                      </Box>
                      </Link>
                    </Box>
                    <Link to={`publicationdetail/${item?.scholar_id}`} className="a-class">
                    <Box id="descriptionPageGrid" className="publication-buttons-box-red">
                      <img src={whiteCalender} alt="whiteCalender" />
                      <Box className="publication-button-text-white" id="classification-page">
                        View More
                      </Box>
                    </Box>
                    </Link>
                  </Box>
                  </MarginSlide>
                </Box>
              </Grid>
            ))}
          </Grid>
        </PublicationLandingPageContainerCss>
      </>
    );
  }
}

// Customizable Area Start
const MarginSlide = styled(Box)(({ theme }) => ({
  position: "relative",
  bottom: "10%"
}));
const StyledPublicationMainText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '16px',
  marginBottom: '24px',
  gap: '10px', // Add space between checkbox and text
}));
const PublicationLandingPageContainerCss = styled(Box)({
  "& .publication-white-container": {
    background: "#F5F5F4",
    borderRadius: 8,
    maxHeight: "878px",
    overflowY: "scroll",
    padding: "32px 24px",
  },
  "& .publication-white-container-gridView": {
    background: "#F5F5F4",
    borderRadius: 8,
    maxHeight: "939px",
    overflowY: "scroll",
    padding: "32px 16px",
  },
  "& .publication-main-text": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "20px",
    marginBottom: "-5% !important",
    display: 'block',
    overflow: 'hidden', 
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis',
    lineHeight: "24px", 
  },
  "& .box-span": {
    color: "#DF4833"
  },
  "& .publication-Id-btn": {
    width: "fit-content",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "58px",
    background: "#FCEDEB",
    padding: "0px 24px",
    borderRadius: 8,
    marginTop: "24px"
  },
  "& .publication-Id-btn-text": {
    color: "#DF4833",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 16,
    textTransform: "none",
  },

  "& .publication-Id-btn-text-author":{
    display: "flex",
    fontFamily: "Inter",
    fontWeight: 400, // Change font weight to normal
    width: "fit-content",
    borderRadius: 8,
    margin: "7px 0",
    textTransform: "none",
    fontSize: 14,
    alignItems: "center",
    gap: 8,
    border: 'none',
    padding: "0px 7px",
    height: 58,
  },
  "& .text-box-main": {
    // marginTop: 24,
  },
  "& .publication-text-author": {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    color: "#212324",
    marginBottom: "7px",
    lineHeight: "28px",
  },
  "& .publication-text-bold": {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    color: "#212324",
    marginBottom: "7px",
    lineHeight: "28px",
  },
  "& .publication-p-text": {
    color: "#4F5356",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "28px",
    fontFamily: "Inter",
    marginBottom: "5px 0 15px 0",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "5em"
  },
  "& .publication-small-text": {
    color: "#727578",
    fontSize: 16,
    cursor: "pointer",
    width: "100%",
    fontWeight: 400,
    margin: "15px 0",
    lineHeight: "28px",
    fontFamily: "Inter",
  },
  "& .a-class": {
    textDecoration: "none",
    color: "#727578",
    cursor: "pointer",
    wordBreak: "break-all"
  },
  "& .publication-li-text": {
    color: "#4F5356",
    fontSize: 20,
    fontWeight: 400,
    lineHeight: "28px",
    fontFamily: "Inter",
  },
  "& .publication-li-text-red": {
    color: "#DF4833",
    fontSize: 20,
    fontWeight: 400,
    lineHeight: "28px",
    fontFamily: "Inter",
  },
  "& .publication-buttons-main-box": {
    marginTop: 24,
    gap: 20,
    flexWrap: "wrap",
    display: "flex",
  },
  "& .publication-buttons-main-box-author": {
    gap: 20,
    flexWrap: "wrap",
    display: "flex",
  },
  "& .publication-buttons-box": {
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    borderRadius: "8px",
    border: "1px solid #AEB0B1",
    background: "#F5F5F4",
    padding: "10px 16px",
    alignItems: "center",
  },
  "& .publication-buttons-box-red": {
    padding: "10px 16px",
    gap: "10px",
    borderRadius: "8px",
    cursor: "pointer",
    alignItems: "center",
    background: "#DF4833",
    display: "flex",
  },
  "& .publication-button-text": {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Inter",
    color: "#4F5356",
  },
  "& .publication-button-text-white": {
    fontSize: "16px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: 400,
  },
});
// Customizable Area End
